import { inject, Injectable } from '@angular/core';

import { NgxRxAlertModel, NgxRxAlertService, okTo } from 'ngx-rx-alert';
import { forkJoin, switchMap } from 'rxjs';

import { ROLES } from '@alan-apps/api-interfaces';
import {
  AllLifeFunctionsGQL,
  AllMembersGQL,
  AllPropertyDevicesGQL,
  AllPropertyFacesGQL,
  AllPropertyFurnituresGQL,
  AllPropertyKindsGQL,
  AllPropertyKindsValueGQL,
  AllPropertyPublicUtilitiesGQL,
  AllPropertyShapesGQL,
  AllPropertyShapesValueGQL,
  AllPropertyUsagesGQL,
  AllRentAttachmentsGQL,
  CreatePropertyOrderGQL,
  PropertyOrderInput,
} from '@alan-apps/data-access';
import {
  BaseHttpService,
  BaseService,
  cache,
  ICache,
} from '@nghedgehog/angular-ui';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OrderService implements ICache {
  readonly storageKey = 'OrderService';

  private translate = inject(TranslateService);

  constructor(
    private _http: BaseHttpService,
    private _base: BaseService,
    private _alc: NgxRxAlertService,
    private allMember: AllMembersGQL,
    private allRentAttachments: AllRentAttachmentsGQL,
    private allPropertyDevices: AllPropertyDevicesGQL,
    private allPropertyFurnitures: AllPropertyFurnituresGQL,
    private allPropertyPublicUtilities: AllPropertyPublicUtilitiesGQL,
    private allLifeFunctions: AllLifeFunctionsGQL,
    private allPropertyKindsValue: AllPropertyKindsValueGQL,
    private allPropertyKinds: AllPropertyKindsGQL,
    private allPropertyFaces: AllPropertyFacesGQL,
    private allPropertyShapesValue: AllPropertyShapesValueGQL,
    private allPropertyShapes: AllPropertyShapesGQL,
    private allPropertyUsages: AllPropertyUsagesGQL,
    private createPropertyOrder: CreatePropertyOrderGQL,
  ) {}

  @cache()
  getAllSeller(departmentId?: string) {
    return this._http.apollo(
      this.allMember.fetch({ departmentId, roleLevel: ROLES.MEMBER }),
    );
  }

  @cache()
  getRent_attachments() {
    return this._http.apollo(this.allRentAttachments.fetch());
  }

  @cache()
  getDevices() {
    return this._http.apollo(this.allPropertyDevices.fetch());
  }

  @cache()
  getFurnitures() {
    return this._http.apollo(this.allPropertyFurnitures.fetch());
  }

  @cache()
  getPublicUtilities() {
    return this._http.apollo(this.allPropertyPublicUtilities.fetch());
  }

  @cache()
  getLifeFunctions() {
    return this._http.apollo(this.allLifeFunctions.fetch());
  }

  @cache()
  getKinds() {
    return this._http.apollo(this.allPropertyKinds.fetch());
  }

  @cache()
  getKindsValue() {
    return this._http.apollo(this.allPropertyKindsValue.fetch());
  }

  @cache()
  getFaces() {
    return this._http.apollo(this.allPropertyFaces.fetch());
  }

  @cache()
  getShapes() {
    return this._http.apollo(this.allPropertyShapes.fetch());
  }

  @cache()
  getShapesValue() {
    return this._http.apollo(this.allPropertyShapesValue.fetch());
  }

  @cache()
  getUsages() {
    return this._http.apollo(this.allPropertyUsages.fetch());
  }

  add(input: PropertyOrderInput) {
    return this._http.apollo(this.createPropertyOrder.mutate({ input })).pipe(
      okTo((createPropertyOrder) => {
        const id = createPropertyOrder.id;

        this.print(id).subscribe();

        return forkJoin([
          this.translate.get('成功送出'),
          this.translate.get('已成功送出委託，將派專員為您處理。'),
        ]).pipe(
          switchMap(([title, content]) =>
            this._alc.alert(new NgxRxAlertModel(title, content, 'success')),
          ),
        );
      }),
    );
  }

  print(id: number) {
    return this._base.openUrl(`/api/print/${id}`);
  }
}
